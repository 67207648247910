

const Contact = () => {

    return (
        <div>
            <h1>Contact Page</h1>
        </div>
    )
}

export default Contact